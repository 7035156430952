
import { defineComponent, computed } from 'vue'
import ReportCards from '@/components/shared/templates/reports/ReportCards.vue'
import ReportingNavigation from '@/components/pages/reporting/ReportingNavigation.vue'
import ReportingTimeline from '@/components/pages/reporting/ReportingTimeline.vue'
import ReportingOverviewLiveChatsEmpty from '@/components/pages/reporting/overview/ReportingOverviewLiveChatsEmpty.vue'
import { reportsFilter } from '@/definitions/shared/filters/filtersList/data'
import { useFilters } from '@/compositions/filters'
import { useModes } from '@/compositions/modes'
import { reportsOverviewLiveChatsStats, reportsOverviewLiveChatsStatsHalfEmpty, timelineLiveChatsOptions } from '@/definitions/reporting/overview/data'
import { reportingOverviewLiveChatsTimelineChartData, reportingOverviewLiveChatsTimelineChartDataHalfEmpty } from '@/definitions/shared/charts/reporting/data'

export default defineComponent({
  components: {
    ReportCards,
    ReportingNavigation,
    ReportingTimeline,
    ReportingOverviewLiveChatsEmpty,
  },
  setup() {
    const { isEmptyMode, isEmptyHalfMode } = useModes()
    const { filters } = useFilters(reportsFilter)

    const stats = computed(() => isEmptyHalfMode.value ? reportsOverviewLiveChatsStatsHalfEmpty : reportsOverviewLiveChatsStats)
    const timelineChart = computed(() => isEmptyHalfMode.value ? reportingOverviewLiveChatsTimelineChartDataHalfEmpty : reportingOverviewLiveChatsTimelineChartData)

    return {
      isEmptyMode,
      filters,
      stats,
      timelineChart,
      timelineLiveChatsOptions,
    }
  },
})
